import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { MenuNav, ShopMenuRepository, Shops } from '@infrab4a/connect';
import { from } from 'rxjs';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  @ViewChild('lgpd')
  eRef: ElementRef;

  mobileMenu = false;

  fixedNav = false;
  routesShop: Array<MenuNav>;
  isLaptop = false;
  isMobile = false;
  isTablet = false;
  isDesktop = false;
  showLgpd = true;
  isPlatformBrowserStatus = false;

  constructor(
    @Inject(PLATFORM_ID) private platform: object,
    @Inject('ShopMenuRepository')
    private readonly shopMenuRepository: ShopMenuRepository
  ) {}

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    if (!this.mobileMenu) {
      setTimeout(() => {
        this.fixedNav = this.isLaptop
          ? document.documentElement.scrollTop > 44
          : document.documentElement.scrollTop > 110;
      }, 500);
      BroadcastUtil.get('pageScroll').emit();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.stylePage();
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.isPlatformBrowserStatus = true;
      if (localStorage.getItem('lgpdCookie')) this.showLgpd = false;
      this.stylePage();
      from(this.shopMenuRepository.get({ id: Shops.MENSMARKET })).subscribe(
        (shopMenu) => {
          this.routesShop = shopMenu.menuNav;
          const { menuNav } = shopMenu;
          if (menuNav) {
            this.routesShop = menuNav.filter(
              (item) => Boolean(item.position) && item.position !== ''
            );
            this.routesShop.sort(
              (a, b) => Number(a.position) - Number(b.position)
            );
          }
        }
      );
    }
  }

  stylePage(): void {
    if (isPlatformBrowser(this.platform)) {
      this.isMobile = window.innerWidth < 480;
      this.isTablet = window.innerWidth < 768;
      this.isLaptop = window.innerWidth < 1024;
      this.isDesktop = window.innerWidth > 1366;
    }
  }

  handleLgpd(): void {
    if (isPlatformBrowser(this.platform)) {
      localStorage.setItem('lgpdCookie', 'true');
      this.showLgpd = false;
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any): void {
    if (!this.eRef?.nativeElement.contains(event.target)) this.handleLgpd();
  }
}
