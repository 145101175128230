import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  CategoryRepository,
  MenuNav,
  Product,
  ProductsIndex,
  Shops,
  Subscription,
  User,
  Where
} from '@infrab4a/connect';
import { Cart } from '@infrab4a/connect-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { from } from 'rxjs';
import { ShopFiltersProvider } from 'src/app/providers/shop-filters.provider';
import { StateService } from 'src/app/services/state.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';
import { TextUtil } from 'src/app/utils/text.util';

@Component({
  selector: 'components-header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.scss']
})
export class HeaderNavigationComponent implements OnInit {
  @Input()
  routesShop: MenuNav[];

  @Input()
  cart: Cart;

  @Input()
  user: User;

  @Input()
  subscriber: Subscription;

  @Input()
  clubRoutes: Array<any>;

  isMobile = false;
  isLaptop = false;

  search: FormControl = new FormControl('');
  resultsForShow: Product[];
  loading: boolean;
  marcas: Array<{ label: string; path: string }>;
  isSearch = false;

  searchDebounce = _.debounce((term) => this.searchChange(term), 500);
  searchOpened = false;
  openedMenu: MenuNav;
  selectedIndex = -1;
  clubMenu: { label: string; path: string }[];

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.stylePage();
  }

  constructor(
    public router: Router,
    @Inject(PLATFORM_ID) private platform: object,
    @Inject('CategoryRepository')
    private categoryRepository: CategoryRepository,
    public modalService: NgbModal,
    private http: HttpClient,
    private shopFiltersProvider: ShopFiltersProvider,
    private stateService: StateService,
    private productsElasticSearch: ProductsIndex
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.marcas = [];
      this.getMarcas();
      this.stylePage();
      BroadcastUtil.get('pageScroll').subscribe(() => {
        delete this.openedMenu;
      });
      BroadcastUtil.get('closeMenuNav').subscribe((result) => {
        if (result) this.openedMenu = null;
      });
    }
  }

  stylePage(): void {
    delete this.openedMenu;
    this.isMobile = window.innerWidth < 1024;
    this.isLaptop = window.innerWidth >= 1024 || window.innerWidth < 1280;
  }

  toTitleCase(text: string): string {
    const keepIt = ['a-c', 'c-f', 'g-l', 'm-n', 'n-s', 's-z'];

    if (keepIt.includes(text.toLowerCase())) return text.toUpperCase();

    return TextUtil.clearBrandName(text);
  }

  shopNavigate(link: string, query: string): void {
    if (isPlatformBrowser(this.platform) && link.startsWith('https')) {
      window.location.href = link;
    } else {
      this.router.navigate([link], {
        queryParams: query ? { [query]: 1 } : {}
      });
      delete this.openedMenu;
    }
  }

  toggleSearch(): void {
    this.searchOpened = !this.searchOpened;
  }

  isSelected(link: string): boolean {
    return this.router.url.includes(link);
  }

  showSubmenu(route: MenuNav, i: number): void {
    this.selectedIndex = i;

    if (route.subMenu?.length) this.openedMenu = route;
    else delete this.openedMenu;
  }

  goToSearch(): void {
    if (this.search.value.trim()) {
      this.router.navigate([`/search/${this.search.value.trim()}`]);
      this.clearResults();
    }
  }

  hasSubChildMenu(routes: MenuNav): boolean {
    return routes.subMenu.filter((sm) => sm.subChildMenu.length).length > 0;
  }

  goTo(path: string, query?: string): void {
    const route = path.includes('?') ? path.split('?').at(0) : path;
    const queryParams = path.includes('?') ? path.split('?').at(1) : '';
    if (route.startsWith('http')) window.open(route, '_blank');
    else if (route.includes('paraela'))
      this.router.navigate([route], { queryParams: { gender: 'female' } });
    else
      this.router.navigate([route], {
        queryParams: queryParams.includes('gender=female')
          ? { gender: 'female' }
          : {}
      });
  }

  async searchChange(search: string): Promise<void> {
    if (!this.isMobile) {
      search = search.trim();

      if (!search.length) return;

      this.loading = true;

      from(
        this.productsElasticSearch.search(search, 9, Shops.MENSMARKET)
      ).subscribe({
        next: (res) => this.handleSearchResult(res as SearchResponse<Product>),
        error: () => (this.loading = false)
      });
    }
  }

  handleSearchResult(res: SearchResponse<Product>): void {
    const searchResults = res.hits.reduce(
      (acc: Product[], result: Hit<Product>) => [...acc, result._source],
      []
    );

    this.resultsForShow = searchResults
      ? searchResults.reduce((acc: Product[], cur: Product, index) => {
          if (index < 9) acc.push(cur);

          return acc;
        }, [])
      : [];

    this.loading = false;
  }

  clearResults(): void {
    this.resultsForShow = null;
    this.loading = false;
  }

  getMarcas(): void {
    this.categoryRepository
      .find({
        filters: {
          brandCategory: { operator: Where.EQUALS, value: true },
          shop: { operator: Where.EQUALS, value: Shops.MENSMARKET }
        }
      })
      .then((categoriesSnap) => {
        const categories = categoriesSnap.data;

        this.marcas = categories.map((category) => {
          const brandName = category.name.replace(/marca - /g, '');

          return {
            label: brandName,
            path: `/collections/${category.slug}`
          };
        });

        this.marcas.sort((a, b) =>
          a.label
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim() >
          b.label
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim()
            ? 1
            : -1
        );
      });
  }

  get totalItensCart(): number {
    return this.cart ? Object.keys(this.cart).length : 0;
  }

  onClickOpenCart(): void {
    this.stateService.openCart();
  }
}

type SearchResponse<T> = {
  total: number;
  hits: Hit<T>[];
};

type Hit<T> = {
  _index: string;
  _type: string;
  _id?: string;
  _score: number;
  _source: T;
  _version?: number;
  _explanation?: Explanation;
  fields?: unknown;
  highlight?: unknown;
  inner_hits?: unknown;
  matched_queries?: string[];
  sort?: string[];
};

type ShardsResponse = {
  total: number;
  successful: number;
  failed: number;
  skipped: number;
};

type Explanation = {
  value: number;
  description: string;
  details: Explanation[];
};
