import {
  CommonModule,
  registerLocaleData,
  ViewportScroller
} from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { Router, RouterModule, Scroll } from '@angular/router';
import {
  Authentication,
  AuthenticationService,
  Shops,
  UserRepository
} from '@infrab4a/connect';
import {
  AngularConnectModule,
  AngularFirebaseAuthModule
} from '@infrab4a/connect-angular';

import {
  FacebookLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule
} from '@abacritt/angularx-social-login';
import { HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonsModule } from '@commons/commons.module';
import { ComponentsModule } from '@components/components.module';
import { CustomToastComponent } from '@components/custom-toast/custom-toast.component';
import { CarouselModule } from '@infrab4a/components-b4a';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ToastrModule } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { LayoutModule } from './layout/layout.module';
import { ShopFiltersProvider } from './providers/shop-filters.provider';
import { AuthGuard } from './security/auth-guard';

registerLocaleData(localePt);
@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CommonModule,
    AngularSvgIconModule.forRoot(),
    ComponentsModule,
    CommonsModule,
    NgbModule,
    RouterModule.forRoot([], {
      initialNavigation: 'enabledNonBlocking',
      scrollPositionRestoration: 'disabled',
      anchorScrolling: 'enabled'
    }),
    LayoutModule,
    SocialLoginModule,
    AngularConnectModule.initializeApp(Shops.MENSMARKET, {
      firebase: environment.firebase,
      elasticSearch: environment.elasticSearch,
      hasura: {
        endpoint: environment.hasura.endpoint,
        credentials: { adminSecret: environment.hasura.adminSecret }
      },
      backendUrl: environment.apiUrl,
      storageBaseUrl: environment.cloudStorageUrl,
      oldCategoryStructure: false
    }),
    GoogleTagManagerModule.forRoot({ id: environment.gtmId }),
    AngularFirebaseAuthModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      toastComponent: CustomToastComponent,
      closeButton: true,
      timeOut: 5000,
      enableHtml: true,
      preventDuplicates: true
    }),
    CarouselModule
  ],
  providers: [
    AuthGuard,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookAppId)
          }
        ]
      } as SocialAuthServiceConfig
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt'
    },
    {
      provide: 'Authentication',
      useFactory: (
        authenticationService: AuthenticationService,
        userRepository: UserRepository
      ) => new Authentication(authenticationService, userRepository),
      deps: ['AuthenticationService', 'UserRepository']
    },
    ShopFiltersProvider,
    { provide: 'googleTagManagerId', useValue: environment.gtmId }
  ],
  bootstrap: [AppComponent]
})
export class AppBrowserModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    router.events.pipe(filter((e) => e instanceof Scroll)).subscribe((e) => {
      if (!router.url.includes('scrollY')) {
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
}
